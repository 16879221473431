// src/components/Sources.jsx
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../context/AppContext";
import SourceList from "./SourceList"; // Import the SourceList component
import SubjectList from "./SubjectList"; // Import the SubjectList component

const Sources = () => {
  const { state } = useAppContext();
  const { sources, subjects, loading, error } = state;

  if (loading) return <div>Loading sources...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container className="sources">
      <Row>
        <Col md={8} className="d-flex justify-start mt-9">
          <h3 className="h3 fw-bold">
            Os artigos listados provêm dos seguintes sites, utilizando pesquisa
            por palavra-chave:{" "}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-start mt-5 sources-list">
          <SourceList sources={sources} /> {/* Use the SourceList component */}
        </Col>
      </Row>
      <Row>
        <Col md={8} className="d-flex justify-start mb-5 mt-10">
          <h3 className="h3 fw-bold">
            Esta página lista as pesquisas que parecem ter resultados relevantes
            para pessoas com <SubjectList subjects={subjects} />.
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-8">
          <p>
            Para ser listado, um artigo deve cumprir pelo menos um dos
            requisitos:
          </p>
          <ul>
            <li>Indica um novo medicamento ou agente imunossupressor</li>
            <li>
              Sugere um medicamento ou agente que alivia a inflamação no SNC
            </li>
            <li>Promove a produção de mielina</li>
            <li>
              Aponta um medicamento ou agente com propriedades neuroprotetoras
            </li>
            <li>
              Introduz um novo tratamento para os sintomas da EM,
              particularmente a espasticidade
            </li>
            <li>
              Recomenda um tratamento ou medicação que estimule a neurogénese ou
              a regeneração do SNC
            </li>
            <li>
              Confirma a eficácia de um medicamento ou agente (in vitro, in vivo
              ou em ensaio clínico)
            </li>
          </ul>
        </Col>
        <Col md={6} className="mb-8">
          <p>O artigo não é considerado relevante se abordar:</p>
          <ul>
            <li>Fertilidade</li>
            <li>Saúde mental</li>
            <li>Desafios enfrentados pelos cuidadores</li>
            <li>Fisioterapia para controlo de sintomas</li>
            <li>Biomarcadores e outros métodos de diagnóstico</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Sources;
