// src/components/SubjectList.jsx
import React from 'react';

const SubjectList = ({ subjects }) => {
  if (!subjects || subjects.length === 0) {
    return "";
  }

  return (
    <span>
      {subjects.map((subject, index) => (
        <span key={subject.id || index} className="subject-name d-inline">
          {subject.subject_name}
          {index < subjects.length - 1 ? ", " : ""}
        </span>
      ))}
    </span>
  );
};

export default SubjectList;
