// src/components/SourceList.jsx
import React from 'react';
import Button from 'react-bootstrap/Button';
import { useAppContext } from '../context/AppContext';

const SourceList = () => {
  const { state } = useAppContext();
  const { sources, loading, error } = state;

  if (loading) return <div>Loading sources...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!sources || sources.length === 0) return <p>No sources available</p>;

  return (
    <div className="source-list">
      {sources.map((source) => (
        <Button
          size="xs"
          variant="outline-danger"
          className="categories-btn"
          key={source.id || source.name} // Ensure a unique key
          onClick={() => window.open(source.link, "_blank")}
        >
          {source.name}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-external-link"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="#2c3e50"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
            <path d="M11 13l9 -9" />
            <path d="M15 4h5v5" />
          </svg>
        </Button>
      ))}
    </div>
  );
};

export default SourceList;
